
  import { Vue, Component, Watch } from "vue-property-decorator";
  import { readIsLoggedIn, readUserProfile } from "@/store/main/getters";
  import { dispatchUserLogOut } from "@/store/main/actions";

  @Component
  export default class Public extends Vue {
    headerColor = "primary";

    get userProfile() {
      return readUserProfile(this.$store);
    }

    get loggedIn() {
      return readIsLoggedIn(this.$store);
    }

    mounted() {
      this.setHeaderColor();
      window.onscroll = () => {
        this.setHeaderColor();
      };
    }

    @Watch("$route")
    onRouteChanged() {
      this.setHeaderColor();
    }

    setHeaderColor() {
      let color = this.$route.path === "/events" ? "dark_blue" : "primary";
      if (
        [
          "/",
          "/home",
          "/sign-up",
          "/login",
          "/directory",
          "/events",
          "/mdtp",
          "/about",
          "/michael-crary",
          "/giselle-carnaby",
          "/evidence",
          "/terms",
          "/privacy-policy",
          "/refund-policy",
          "/host-seminar",
        ].includes(this.$route.path) &&
        document.body.scrollTop <= 72 &&
        document.documentElement.scrollTop <= 72
      ) {
        color = "#00000000";
      }
      this.headerColor = color;
    }

    public async logout() {
      await dispatchUserLogOut(this.$store);
    }
  }
